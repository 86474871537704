<template>
  <div class="container h-100">
    <div class="row">
      <div class="col">
        <h1><u>Tutorial</u></h1>
        <div class="row">
          <div class="col-lg-6 col-md-12 col-xl-4">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Inhaltsverzeichnis</h4>
              </div>
              <div class="card-body">
                <ol>
                  <li><a class="jumpRef" href="#build-jnr"><h5>Jump'n'Runs bauen</h5></a></li>
                  <li><a class="jumpRef" href="#publish-jnr"><h5>Jump'n'Runs veröffentlichen</h5></a></li>
                  <li><a class="jumpRef" href="#delete-jnr"><h5>Jump'n'Runs löschen</h5></a></li>
                  <li><a class="jumpRef" href="#jnr-tags"><h5>JnR-Tags</h5></a></li>
                  <li><a class="jumpRef" href="#jnr-search"><h5>JnRs suchen</h5></a></li>
                  <li><a class="jumpRef" href="#invite-player-into-duell"><h5>Spieler in ein Duell einladen</h5></a></li>
                  <li><a class="jumpRef" href="#invite-player-to-build"><h5>Spieler zum Mitbauen einladen</h5></a></li>
                  <li><a class="jumpRef" href="#buildmenu"><h5>Baumenü</h5></a></li>
                  <li><a class="jumpRef" href="#jnr-settings"><h5>JnR-Einstellungen</h5></a></li>
                  <li><a class="jumpRef" href="#special-blocks"><h5>Spezial-Blöcke</h5></a></li>
                  <li><a class="jumpRef" href="#armorstands"><h5>Armorstands (Video)</h5></a></li>
                  <li><a class="jumpRef" href="#moving-blocks"><h5>MovingBlocks (Video)</h5></a></li>
                  <li><a class="jumpRef" href="#pressure-plate-settings"><h5>Druckplatten-Einstellungen</h5></a></li>
                  <li><a class="jumpRef" href="#worldedit"><h5>WorldEdit</h5></a></li>
                  <li><a class="jumpRef" href="#potd"><h5>JnR des Tages</h5></a></li>
                  <li><a class="jumpRef" href="#jnr-items"><h5>Items im JnR</h5></a></li>
                  <li><a class="jumpRef" href="#rating-system"><h5>Bewertungssystem</h5></a></li>
                  <li><a class="jumpRef" href="#verified"><h5>Verifizieren von Jump'n'Runs</h5></a></li>
                  <li><a class="jumpRef" href="#commands"><h5>Befehle</h5></a></li>
                  <li><a class="jumpRef" href="#schematica"><h5>Schematica</h5></a></li>
                  <li><router-link class="jumpRef" to="/astpositions"><h5>Armorstand Positionen</h5></router-link></li>

                </ol>
              </div>
            </div>
          </div>
        </div>

        <h2 class="topic" id="build-jnr">Jump'n'Runs bauen <a href="#build-jnr"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <img class="img-fluid rounded tutorial-image" src="../assets/tutorial/build_jnr.png" alt="build_jnr">
        <p>Klicke zuerst auf die <span class="marked">Feuerwerkskugel</span> (siehe Foto). Anschließend kannst du den Bauslot auswählen, welchen du bebauen willst. Je nach Rang hast du 1 – 3 Slots. Im Bauslot kannst du dann die <span class="marked">Spitzhacke</span> anklicken, um das JnR zu bearbeiten. Zum Bearbeiten des Namens kannst du das <span class="marked">Schild</span> anklicken. Solltest du die Tags ändern wollen, kannst du dies über das <span class="marked">Namensschild</span> tun (später genauer erklärt).</p>


        <h2 class="topic" id="publish-jnr">Jump'n'Runs veröffentlichen <a href="#publish-jnr"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <p>Auch hierfür musst du wieder zuerst auf die <span class="marked">Feuerwerkskugel</span> klicken und anschließend den Bauslot auswählen. Nun kannst du die <span class="marked">Rakete</span> anklicken, welche dich in den JnR-Modus versetzt, damit du dein JnR einmal durchspielen kannst. Hast du dein JnR erfolgreich absolviert, musst du erneut deinen Bauslot auswählen. Jetzt kannst du über den <span class="marked">grünen Haken</span> das JnR veröffentlichen. In diesem Zuge kannst du auch auswählen, ob Replays von diesem JnR nur für Teamler oder für alle sichtbar sein sollen.</p>


        <h2 class="topic" id="delete-jnr">Jump'n'Runs löschen <a href="#delete-jnr"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <h6><u>Im Baumodus</u></h6>
        <p>Wie bereits zum Bauen und Veröffentlichen eines JnRs musst du hierfür deinen Bauslot auswählen. Jetzt kannst du den <span class="marked">roten X-Kopf</span> anklicken. Nach Befolgen der Anweisungen ist dein JnR gelöscht.</p>
        <h6><u>Bereits veröffentlichte JnRs</u></h6>
        <p>Auch diese Funktion ist in der <span class="marked">Feuerwerkskugel</span> eingebaut. Dieses Mal musst du das <span class="marked">Papier</span> anklicken. Danach kannst du das JnR auswählen, welches du löschen willst. Abschließend sind nur noch die Anweisungen zu befolgen und dein JnR ist gelöscht.</p>


        <h2 class="topic" id="jnr-tags">JnR-Tags <a href="#jnr-tags"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <p>JnRs können mit Tags markiert werden, nach welchen später die Spieler suchen können. Tags können während der Bauphase vergeben werden. Dazu musst du wieder zuerst die <span class="marked">Feuerwerkskugel</span> rechtsklicken. Anschließend wählst du deinen Bauslot aus und klickst das <span class="marked">Namensschild</span> an. Hier hast du dann die Möglichkeit, dein JnR genauer zu definieren. Hast du z.B. ein Quiz mit Elytra-Part, so kannst du den Tag für das Quiz und den Tag für ein Elytra-JnR aktivieren. Ob ein Tag aktiv ist, erkennst du am <span class="marked">grünen Farbstoff</span>.</p>


        <h2 class="topic" id="jnr-search">JnRs suchen <a href="#jnr-search"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <img class="img-fluid rounded tutorial-image" src="../assets/tutorial/search_easy.png" alt="search_easy">
        <ol>
          <li>Hier kannst du nach schnellen und leichten JnRs suchen. Das bedeutet, dass das JnR mindestens 35x bewertet wurde und vom Team als gutes JnR eingestuft wurde. Die JnRs haben dabei eine ungefähre Durchschnittszeit von 6 Minuten und sind nach Durchschnittszeit sortiert. JnRs mit den Tags „Quiz“ und „Hardcore“ werden nicht angezeigt.</li>
          <li>Hier kannst du nach etwas schwierigeren und längeren JnRs suchen. Das bedeutet, dass das JnR mindestens 35x bewertet wurde und vom Team als gutes JnR eingestuft wurde. Die JnRs haben dabei eine ungefähre Durchschnittszeit von 15 Minuten und sind nach Durchschnittszeit sortiert. JnRs mit den Tags „Quiz“ und „Hardcore“ werden nicht angezeigt.</li>
          <li>Detaillierte Suche:</li>
        </ol>
        <img class="img-fluid rounded tutorial-image" src="../assets/tutorial/search_advanced.png" alt="search_advanced">
        <ol>
          <li class="custom-li" customcontent="1-3:">Einschränkung der Suche auf Tagessieger, Favoriten oder eigene JnRs.</li>
          <li class="custom-li" customcontent="4-6:">Filterung nach gespielten oder geschafften JnRs.</li>
          <li class="custom-li" customcontent="8-11:">Reihenfolge der Suchergebnisse nach Alter, Bewertung, Schwierigkeit des JnRs oder Durchschnittszeit.</li>
          <li class="custom-li" customcontent="13-14:">Eingrenzung der Suche nach Erscheinungsdatum und minimaler Bewertung.</li>
        </ol>
        <ol>
          <li>
            <span class="marked">Alle JnRs</span> werden durchsucht.
          </li>
          <li>
            <span class="marked">Nur Tagessieger</span> werden durchsucht.
          </li>
          <li>
            <span class="marked">Grüner</span> Farbstoff: Nur <span class="marked">favorisierte JnRs</span> werden durchsucht.<br>
            <span class="marked">Blauer</span> Farbstoff: Nur <span class="marked">eigene JnRs</span> werden durchsucht.<br>
            <span class="marked">Dunkelgrüner</span> Farbstoff: Nur <span class="marked">JnRs von Freunden</span> werden durchsucht.</li>
          <li>
            <span class="marked">Alle JnRs</span> werden durchsucht.
          </li>
          <li>
            <span class="marked">Grüner</span> Farbstoff: Nur bereits <span class="marked">gespielte JnRs</span> werden durchsucht.<br>
            <span class="marked">Roter</span> Farbstoff: Nur noch <span class="marked">nicht gespielte JnRs</span> werden durchsucht.
          </li>
          <li>
            <span class="marked">Grüner</span> Farbstoff: Nur bereits <span class="marked">geschaffte JnRs werden</span> durchsucht.<br>
            <span class="marked">Roter</span> Farbstoff: Nur noch <span class="marked">nicht geschaffte JnRs</span> werden durchsucht.
          </li>
          <li>
            Nach dem Namen des JnRs suchen (Erbauer mit “name:[Erbauer]”).
          </li>
          <li>
            <span class="marked">Grüner</span> Farbstoff: Nach <span class="marked">Alter der JnRs</span> sortieren.<br>
            <span class="marked">Blauer</span> Farbstoff: Nach <span class="marked">zuletzt gespielten JnRs</span> sortieren.
          </li>
          <li>
            Hier werden nur JnRs angezeigt, die <span class="marked">mindestens 10x bewertet</span> wurden.<br>
            <span class="marked">Grüner</span> Farbstoff: JnRs mit <span class="marked">bester Bewertung</span> zuerst.<br>
            <span class="marked">Roter</span> Farbstoff: JnRs mit <span class="marked">schlechtester Bewertung</span> zuerst.
          </li>
          <li>
            Hier werden nur JnRs angezeigt, welche <span class="marked">mindestens 25x gespielt</span> wurden.<br>
            <span class="marked">Grüner</span> Farbstoff: <span class="marked">Schwerste JnRs</span> zuerst.<br>
            <span class="marked">Roter</span> Farbstoff: <span class="marked">Leichteste JnRs</span> zuerst.
          </li>
          <li>
            <span class="marked">Grüner</span> Farbstoff: JnRs mit <span class="marked">längster Durchschnittszeit</span> zuerst.<br>
            <span class="marked">Blauer</span> Farbstoff: JnRs mit <span class="marked">kürzester Durchschnittszeit</span> zuerst.
          </li>
          <li>
            Nur nach bestimmten Tags suchen.
          </li>
          <li>
            Steinknopf: Erscheinungsdatum erweitern.<br>
            Holzknopf: Erscheinungsdatum eingrenzen.<br>
            Standardmäßig: Unbegrenztes Erscheinungsdatum.
          </li>
          <li>
            Steinknopf: Minimale Bewertung erhöhen.<br>
            Holzknopf: Minimale Bewertung verringern.<br>
            Standardmäßig: Minimale Bewertung von 0.0.
          </li>
          <li>
            <span class="marked">Suche starten</span>.
          </li>
        </ol>


        <h2 class="topic" id="invite-player-into-duell">Spieler in ein Duell einladen <a href="#invite-player-into-duell"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <p>Um ein Spieler in ein Duell einzuladen, hast du zwei Möglichkeiten. Die 1. Möglichkeit ist, den Spieler mit deinem <span class="marked">Schwert</span> in der Lobby zu schlagen. Alternativ kannst du den Spieler auch mit <span class="marked">/duel invite &lt;NAME&gt;</span> einladen.</p>


        <h2 class="topic" id="invite-player-to-build">Spieler zum Mitbauen einladen <a href="#invite-player-to-build"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <p>Damit ein anderer Spieler dir beim Bauen helfen kann, musst du ihn zuerst in ein Duell einladen. Ist das geschehen, kannst du den Baumodus deines JnRs betreten. Sollte der Spieler nicht mit teleportiert werden oder du bist bereits im Baumodus, kann der Spieler mit <span class="marked">/join &lt;NAME&gt;</span> beitreten.</p>


        <h2 class="topic" id="buildmenu">Baumenü <a href="#buildmenu"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <img class="img-fluid rounded tutorial-image" src="../assets/tutorial/build_menu.png" alt="build_menu">
        <ol>
          <li>Lege das <span class="marked">Icon</span> deines JnRs fest (erst ab Pro+).</li>
          <li>Die wichtigsten Befehle in einer Übersicht.</li>
          <li>Die <span class="marked">JnR-Einstellungen</span> (weiter unten genauer erklärt).</li>
          <li>Ziel-Druckplatte (Eine pro JnR)</li>
          <li>Checkpoint-Druckplatte (Mehrere pro JnR)</li>
          <li>Effekte-Druckplatte (Mehrere pro JnR)</li>
          <li><span class="marked">Skull</span>-Menü (erst ab <span class="mvp">MVP</span>).</li>
          <li><span class="marked">Armorstands</span> (erst ab <span class="mvp">MVP</span> – weiter unten genauer erklärt).</li>
          <li>Spezial-Blöcke (weiter unten genauer erklärt).</li>
          <li><span class="marked">Portale</span>: Platziere zwei Banner einer Farbe. Durch einen Recktsklick auf die Banner kannst du die Banner als Portale verknüpfen.</li>
          <li><span class="marked">Kristalle</span>: In einem JnR kannst du bis zu drei Kristalle verbauen, welche Spieler sammeln können. Zum Veröffentlichen deines JnRs ist es nicht notwendig, diese einzusammeln.</li>
          <li><span class="marked">Mobs</span> zum Verzieren: die Anzahl variiert je nach Rang.</li>
          <li>MovingBlocks (weiter unten genauer erklärt).</li>
          <li><span class="marked">Fallender Amboss</span>: Platzierst du diesen Amboss in der Luft, fällt er alle 1,5 Sekunden nach unten. Bei Berührung mit einem Spieler wird dieser getötet.</li>
        </ol>


        <h2 class="topic" id="jnr-settings">JnR-Einstellungen <a href="#jnr-settings"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <img class="img-fluid rounded tutorial-image" src="../assets/tutorial/jnr_settings.png" alt="build_menu">
        <p>
          In diesem Menü gibt es immer zwei Einstellungsmöglichkeiten:<br>
          Grün: <span class="marked">aktiviert</span>.<br>
          Grau: <span class="marked">deaktiviert</span>.
        </p>
        <ol>
          <li>Spezial-Blöcke</li>
          <li>Immer Sprinten: Du darfst <span class="marked">nie aufhören zu sprinten</span>. Tust du es doch, wirst du zum letzten Checkpoint zurückgesetzt.</li>
          <li>Nicht Springen: Du kannst das gesamte JnR über <span class="marked">nicht springen</span>.</li>
          <li>Natürlicher Schaden: Du erhältst ganz <span class="marked">regulär Schaden</span>. Verlierst du alle Herzen, wirst du zum letzten Checkpoint zurückgesetzt.</li>
          <li>In Wasser zurücksetzen: Bei <span class="marked">Kontakt mit Wasser</span> wirst du zum letzten Checkpoint zurückgesetzt.</li>
          <li>MovingBlocks</li>
          <li>Nicht sprinten: Du kannst das gesamte JnR über <span class="marked">nicht sprinten.</span>.</li>
          <li>Nicht schleichen: Du <span class="marked">darfst nicht schleichen</span>. Tust du es doch, wirst du zum letzten Checkpoint zurückgesetzt.</li>
          <li>Bei Schaden zurücksetzen: Sobald du ein <span class="marked">halbes Herz Schaden</span> erhältst, wirst du sofort zum letzten Checkpoint zurückgesetzt.</li>
          <li>In Lava zurücksetzen: Bei <span class="marked">Kontakt mit Lava</span> wirst du zum letzten Checkpoint zurückgesetzt.</li>
        </ol>


        <h2 class="topic" id="special-blocks">Spezial-Blöcke <a href="#special-blocks"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <img class="img-fluid rounded tutorial-image" src="../assets/tutorial/special_effects.png" alt="special_effects">
        <ol>
          <li>Speed (Schnelligkeit).</li>
          <li>Jump-Boost (Sprungkraft).</li>
          <li>Death (Tod).</li>
          <li>Teleport Up (Hochteleportieren).</li>
          <li>Teleport Down (Herunterteleportieren).</li>
          <li>Levitation.</li>
          <li>Slowness (Langsamkeit).</li>
          <li>Blindness (Blindheit).</li>
        </ol>


        <h2 class="topic" id="armorstands">Armorstands (Video) <a href="#armorstands"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <iframe width="420" height="315"
                src="https://www.youtube.com/embed/HdYZzrd5Uag">
        </iframe>


        <h2 class="topic" id="moving-blocks">MovingBlocks (Video) <a href="#moving-blocks"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <iframe width="420" height="315"
                src="https://www.youtube.com/embed/g-4IzjUYdWU">
        </iframe>


        <h2 class="topic" id="pressure-plate-settings">Druckplatten-Einstellungen <a href="#pressure-plate-settings"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <img class="img-fluid rounded tutorial-image" src="../assets/tutorial/pressure_plate_settings.png" alt="pressure_plate_settings">
        <p>Um zu diesen Einstellungen zu gelangen, musst du eine Druckplatte mit der <span class="marked">leeren Hand rechtsklicken</span>.</p>
        <ol>
          <li>
            Stelle <span class="marked">Uhrzeit</span> und <span class="marked">Wetter</span> ein, die der Spieler bei Berühren der Druckplatte sehen soll.
          </li>
          <li>
            <span class="marked">Anzahl</span> der <span class="marked">Enderperlen</span>, die ein Spieler bei Berühren der Druckplatte erhält. Sollte der Spieler noch Enderperlen besitzen,
            können diese ihm hier entfernt werden.</li>
          <li>
            Vergib verschiedenste <span class="marked">Effekte</span>. Sollte ein Spieler bereits einen Effekt besitzen, kann dieser hier auch entfernt werden.
          </li>
          <li>
            Lege eine <span class="marked">Mindesthöhe</span> fest. Unterschreitet der Spieler nach der Druckplatte diese Höhe, wird er zurückgesetzt. Sollte eine Mindesthöhe bereits aktiv sein, kann diese hier
            auch entfernt werden.
          </li>
          <li>
            Vergib <span class="marked">Effekte</span>, welche die <span class="marked">Fortbewegung beeinträchtigen</span>.
            Zur Auswahl stehen <span class="marked">„Immer sprinten“</span>, <span class="marked">„Nicht sprinten“</span>,
            <span class="marked">„Nicht springen“</span> und <span class="marked">„Nicht schleichen“</span> (erklärt wurden diese bereits bei den JnR-Einstellungen).
            Sollte ein Spieler bereits Movement-Effekte haben, können diese hier auch wieder entfernt werden.
          </li>
          <li>
            Bei dieser Einstellung kann die <span class="marked">Elytra</span> aktiviert und die <span class="marked">Anzahl</span> der <span class="marked">Raketen</span> festgelegt werden. Sollte ein Spieler bereits Raketen besitzen, können diese hier auch wieder entfernt werden.
          </li>
          <li>
            Vergib oder entferne einen <span class="marked">Dreizack</span>.
          </li>
          <li>
            <span class="marked">Teleportiere</span> den <span class="marked">Spieler</span>, sobald er die Druckplatte berührt, an eine andere Koordinate.
          </li>
          <li class="custom-li" customContent="9&11.">Gib der <span class="marked">Druckplatte</span> einen <span class="marked">Unsichtbarkeitseffekt</span>, damit sie im JnR nicht mehr sichtbar ist.
          </li>
          <li value="10"><span class="marked">Verbinde</span> die Druckplatte mit der nächsten, um Abkürzungen zu verhindern.
          </li>
        </ol>


        <h2 class="topic" id="worldedit">WorldEdit <a href="#worldedit"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <p>
          WorldEdit wird abhängig des Ranges vergeben. <span class="marked">Spieler</span> haben hierbei keine WorldEdit-Rechte, wobei sie durch das <span class="marked">Voten</span> für einen Tag das Recht auf 8x8x8-WorldEdit (<span class="marked">512 Blöcke</span>) erlangen. Mit <span class="pro">Pro</span> erlangt man dann schon das Recht auf 16x16x16-WE (<span class="marked">4.096 Blöcke</span>), mit <span class="pro">Pro+</span> auf 24x24x24-WE (<span class="marked">13.824 Blöcke</span>), mit <span class="mvp">MVP</span> auf 32x32x32-WE (<span class="marked">32.768 Blöcke</span>), mit <span class="mvp">MVP+</span> 48x48x48-WE (<span class="marked">110.952 Blöcke</span>) und mit <span class="expert">Expert</span> auf unbegrenztes WorldEdit (<span class="marked">500.000 Blöcke</span>).
        </p>

        <div class="row mb-3">
          <div class="col-lg-4 text-center">
            <table class="table ">
              <thead>
                <tr>
                  <th scope="col">Rang</th>
                  <th scope="col">Blöcke</th>
                </tr>
              </thead>
              <tbody>
                <tr><th scope="row" class="player">Spieler</th><td>-</td></tr>
                <tr><th scope="row" class="player">Spieler (Voted)</th><td>512 Blöcke</td></tr>
                <tr><th scope="row" class="pro">Pro</th><td>4.096 Blöcke</td></tr>
                <tr><th scope="row" class="pro">Pro+</th><td>13.824 Blöcke</td></tr>
                <tr><th scope="row" class="mvp">MVP</th><td>32.768 Blöcke</td></tr>
                <tr><th scope="row" class="mvp">MVP+</th><td>110.952 Blöcke</td></tr>
                <tr><th scope="row" class="expert">Expert</th><td>500.000 Blöcke</td></tr>
              </tbody>
            </table>
          </div>
        </div>


        <p>
          Zudem hängen auch die freigeschaltenen Befehle vom Rang ab, jeder Rang hat hierbei Zugriff auf <span class="marked">//set</span>, <span class="marked">//replace</span>, <span class="marked">//undo</span>, <span class="marked">//redo</span>, <span class="marked">//pos1</span> und <span class="marked">//pos2</span>.
        </p>
        <p>
          <span class="marked-notice">HINWEIS</span>: Sollte die Serverauslastung zu groß werden, behält sich das Team vor, die Werte zu reduzieren!
        </p>


        <h2 class="topic" id="potd">JnR des Tages <a href="#potd"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <p>
          Jeden Tag wird automatisch ein JnR des Tages ausgewählt. Ein <span class="marked">Tag</span> auf JumpWorld geht hierbei von <span class="marked">20:01 Uhr</span> des Vortages <span class="marked">bis 20:00 Uhr</span>. <span class="marked">Verkündet</span> wird der Tagessieger dann um <span class="marked">0:00 Uhr</span>. Tagessieger kann jedes JnR werden, welches <span class="marked">mindestens 25x bewertet</span> und von <span class="marked">mindestens 10% der Spieler geschafft</span> wurde. Gewählt wird unter diesen JnRs dann das JnR mit der <span class="marked">besten Bewertung</span>. Dazu werden die JnRs der letzten vier Tage betrachtet. Der Tagessieger ist über das <span class="marked">Netherportal</span> in der Lobby erreichbar.
        </p>


        <h2 class="topic" id="jnr-items">Items im JnR <a href="#build-jnrjnr-items"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <img class="img-fluid rounded tutorial-image" src="../assets/tutorial/parcour_items.png" alt="parcour_items">
        <p>Du erhältst beim Betreten eines JnRs einige Items. Zusätzlich kommen noch weitere Items im Verlauf des JnRs dazu (4 und 5).</p>
        <ol>
          <li>
            Dieses Item <span class="marked">setzt</span> dich <span class="marked">zum letzten Checkpoint zurück</span>. Dafür musst du es einmal rechtsklicken. Hältst du es mit <span class="marked">Rechtsklick gedrückt</span> und <span class="marked">sneakst</span> währenddessen, <span class="marked">startest</span> du das gesamte <span class="marked">JnR neu</span>. Alternativ kannst du auch dein Inventar öffnen und das <span class="marked">Item anklicken</span>, dann <span class="marked">startet</span> das <span class="marked">JnR</span> ebenfalls <span class="marked">neu</span>.
          </li>
          <li>
            Mit diesem Item kannst du einstellen, welche <span class="marked">Spieler</span> du <span class="marked">sehen</span> willst. Zur Auswahl stehen: <span class="marked">„Alle Spieler“</span>, <span class="marked">„Spieler der nächsten drei Blöcke“</span>, <span class="marked">„Nur Freunde“</span>, <span class="marked">„Keine Spieler“</span>.
          </li>
          <li>
            Rechtsklickst du dieses Item, werden dir im <span class="marked">Chat</span> alle <span class="marked">Informationen</span> über das <span class="marked">JnR</span> angezeigt.
          </li>
          <li>
            Mit diesem Item kannst du das JnR <span class="marked">bewerten</span>. Du erhältst es <span class="marked">nach fünf Minuten</span> oder nach <span class="marked">Beenden</span> des JnRs.
          </li>
          <li>
            Den Kompass erhältst du, wenn du in den <span class="marked">Zuschauermodus</span> (/spec) gehst oder das <span class="marked">JnR beendest</span>. Solltest du in einem Duell sein, kannst du dich damit zu allen <span class="marked">Duellmitgliedern teleportieren</span>.
          </li>
          <li>
            Mit diesem Item kannst du das <span class="marked">JnR verlassen</span>. Solltest du <span class="marked">aufgeben</span> wollen, musst das Item <span class="marked">gedrückt halten</span>. Solltest du das <span class="marked">JnR beendet</span> haben, reicht ein <span class="marked">Recktsklick</span> mit diesem Item. Alternativ kannst du auch den Befehl <span class="marked">/l</span> nutzen.
          </li>
        </ol>


        <h2 class="topic" id="rating-system">Bewertungssystem <a href="#rating-system"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <img class="img-fluid rounded tutorial-image" src="../assets/tutorial/rating_system.png" alt="rating_system">
        <p>
          Das Bewertungssystem ist in drei Kategorien aufgeteilt: <span class="marked">Kreativität</span> (1), <span class="marked">Sprünge</span> (2) und <span class="marked">Optik</span> (3). Jede Kategorie kann mit <span class="marked">„sehr gut“</span>, <span class="marked">„gut“</span>, <span class="marked">„okay“</span>, <span class="marked">„mäßig“</span>, <span class="marked">„schlecht“</span> oder <span class="marked">„nicht bewertet“</span> bewertet werden. Um die Bewertung abzugeben, musst du in <span class="marked">mindestens einer Kategorie</span> eine Bewertung auswählen. Um die Bewertung abzusenden, musst du eines der <span class="marked">grünen Gläser</span> (5) anklicken.
        </p>
        <p>
          Sollte dir ein JnR sehr gut gefallen, kannst du es mit dem <span class="marked">Netherstar</span> (4) als <span class="marked">Favorit</span> speichern. Somit findest du es später in der Suche wieder leichter.
        </p>

        <h2 class="topic" id="verified">Verifizieren von Jump'n'Runs <a href="#verified"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <p>
          <span class="marked">Gute und sehr gute</span> Jump and Runs werden von einigen <span class="marked">Teamlern</span> verifiziert, um besondere Qualität anzuzeigen. Dadurch gibt es für das Beenden des Jump and Runs <span class="marked">XP</span> für sein <span class="marked">Level</span> dazu. Außerdem kann man diese dann später im <span class="marked">Suchmenü</span> besser finden und Spaß haben!
        </p>
        <p class="mb-0">Hier die Liste der Teamler die Jump'n'Runs verifizieren können:</p>
        <div class="row mb-3">
          <div class="col-lg-4 text-center">
            <table class="table">
              <thead>
              <tr>
                <th scope="col">User</th>
              </tr>
              </thead>
              <tbody>
                <tr><th scope="row" class="dev">DevMoritz</th></tr>
                <tr><th scope="row" class="mod">ALetsPLay</th></tr>
                <tr><th scope="row" class="sup">Hic</th></tr>
                <tr><th scope="row" class="sup">q8h</th></tr>
                <tr><th scope="row" class="sup">Darkey</th></tr>
                <tr><th scope="row" class="sup">Flauschiq</th></tr>
              </tbody>
            </table>
          </div>
        </div>


        <h2 class="topic" id="commands">Befehle <a href="#commands"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <span>Wir haben zwei verschiedene Befehls-Listen:</span>
        <ol>
          <li><router-link to="/commands/jumpworld">Jumpworld</router-link></li>
          <li><router-link to="/commands/worldedit">WorldEdit</router-link></li>
        </ol>


        <h2 class="topic" id="schematica">Schematica <a href="#schematica"><font-awesome-icon class="ms-2 copy" size="xs" icon="anchor"></font-awesome-icon></a></h2>
        <p>
          Auch wenn es einige Spieler reizend finden, mit Schematica eine vorgebaute Welt auf JumpWorld zu kopieren, müssen wir leider mitteilen, dass die Nutzung von <span class="marked">Schematica</span> <span class="marked-warning">VERBOTEN</span> ist. Jede nachgewiesen Nutzung von Schematica wird geahndet.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tutorial",
  mounted() {
    if(window.location.hash) {
      location.href = window.location.hash;
    }
  }
}
</script>

<style scoped>
.jumpRef {
  color: var(--bs-body-color);;
}

@media (prefers-color-scheme: dark) {
  .jumpRef {
    color: white;
  }
}

.jumpRef:hover {
  color: orange;
}

.custom-li::marker {
  content: attr(customContent) " ";
}

.tutorial-image {
  margin-bottom: .5rem;
}

.topic {
  margin-top: 2rem;
  text-decoration: underline;
  font-weight: bold;
}

.copy:hover {
  color: grey;
  cursor: pointer;
}

.marked-warning {
  color: orangered;
}

.marked-notice {
  color: yellow;
}

.marked {
  color: coral;
}

.player {
  color: #AAAAAA;
}

.pro {
  color: #FFAA00;
}

.mvp {
  color: #55FF55;
}

.expert {
  color: #55FFFF;
}

.admin {
  color: #0000AA;
}

.dev {
  color: #00AA00;
}

.mod {
  color: #AA0000;
}

.sup {
  color: #FFFF55;
}
</style>
